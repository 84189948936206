import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import styles from './404.module.scss';

import quotes from '../utils/errorPageQuotes';
import { randomItem } from '../utils/index';

function Page({ location }) {
    const { author, quote } = randomItem(quotes);

    return (
        <Layout location={location}>
            <Seo title="404" />
            <div className={styles.content}>
                <p className={styles.code}>404</p>
                <h1 className={styles.quote}>{quote}</h1>
                <p>- {author}</p>
            </div>
        </Layout>
    );
}

Page.propTypes = {
    location: PropTypes.object.isRequired
};

export default Page;
