export default [
    {
        author: 'Henry David Thoreau',
        quote: 'Not until we are lost do we begin to understand ourselves.'
    },
    {
        author: 'Martha Graham',
        quote: 'You are unique, and if that is not fulfilled, then something has been lost.'
    },
    {
        author: 'Horace Mann',
        quote: 'Lost, yesterday, somewhere between sunrise and sunset, two golden hours, each set with sixty diamond minutes.'
    },
    {
        author: 'Ozzy Osbourne',
        quote: 'Of all the things I\'ve lost I miss my mind the most.'
    },
    {
        author: 'Daniel Boone',
        quote: 'I have never been lost, but I will admit to being confused for several weeks.'
    },
    {
        author: 'Robert H. Schuller',
        quote: 'Always look at what you have left. Never look at what you have lost.'
    },
    {
        author: 'Christian Nestell Bovee',
        quote: 'When all else is lost, the future still remains.'
    },
    {
        author: 'T. S. Eliot',
        quote: 'Where is all the knowledge we lost with information?'
    },
    {
        author: 'Robert Frost',
        quote: 'Poetry is what gets lost in translation.'
    },
    {
        author: 'Mahmoud Darwish',
        quote: 'Without hope we are lost.'
    },
    {
        author: 'Jon Cryer',
        quote: 'Sarcasm is lost in print.'
    },
    {
        author: 'Elie Wiesel',
        quote: 'When I was young I lost everything.'
    },
    {
        author: 'Matthew Henry',
        quote: 'Not lost, but gone before.'
    },
    {
        author: 'Clarence Darrow',
        quote: 'Lost causes are the only ones worth fighting for.'
    },
    {
        author: 'Christian Lacroix',
        quote: 'We all look for lost time.'
    },
    {
        author: 'Anna Freud',
        quote: 'Children usually do not blame themselves for getting lost.'
    },
    {
        author: 'Pedro Calderon de la Barca',
        quote: 'For even in dreams a good deed is not lost.'
    },
    {
        author: 'Lisa Marie Presley',
        quote: 'Anybody in the spotlight can get lost in that if they are not careful.'
    },
    {
        author: 'Helene Cixous',
        quote: 'Only when you are lost can love find itself in you without losing its way.'
    }
];
